import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: 'vex-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss']
})
export class WysiwygEditorComponent {
  @Input() description = '';
  @Output() descriptionChange = new EventEmitter<string>();
  @Input() disableCopyPaste = false;
  @Input() required = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    height: "15rem",
    toolbarHiddenButtons: [
      ['insertImage'],
      ['insertVideo'],
      ['fontName']
    ]
  };

  constructor() { }

  changeDescription() {
    this.descriptionChange.emit(this.description);
  }

  onCtrlV() {

    if (this.disableCopyPaste) {
      setTimeout(() => {
        const textExample = this.description;
        let strippedTextExample =
          textExample
          .replace(/<(?!b\s*\/?)(?!strong\s*\/?)(?!div\s*\/?)(?!br\s*\/?)(?!pre\s*\/?)(?!ol\s*\/?)(?!ul\s*\/?)(?!li\s*\/?)[^>]+>/g, '');
        this.description = strippedTextExample;
        this.changeDescription();
      }, 100);
    }
  }
}
