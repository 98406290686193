import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getAccountsDashboard(uuid) {
    let finalUrl = `${environment.baseUrl}/dashboard/accounts/${uuid}`;
    
    return this.http.get(finalUrl).pipe(retry(3), map(response => response));
  }

  getBrandsDashboard(uuid) {
    let finalUrl = `${environment.baseUrl}/dashboard/brands/${uuid}`;
    
    return this.http.get(finalUrl).pipe(retry(3), map(response => response));
  }

  deleteDashboard(uuid) {
    let finalUrl = `${environment.baseUrl}/dashboard/${uuid}`;
    
    return this.http.delete(finalUrl);
  }

  updateDashboard(resource) {
    let finalUrl = `${environment.baseUrl}/dashboard`;
    
    return this.http.put(finalUrl, resource);
  }

  createDashboard(resource) {
    let finalUrl = `${environment.baseUrl}/dashboard`;
    
    return this.http.post(finalUrl, resource);
  }

}