import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseResourceService } from './base-resource.service';
import { Brand } from "../models/brand.model";
import { Account } from "../models/account.model";
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AccountService  extends BaseResourceService<Account>{

  //account:Account;
  account = new BehaviorSubject<Account>(null);

  constructor(protected injector: Injector, private httpCliente: HttpClient) {
    super("accounts", injector);
  }

  setAccountData(account:Account){
    this.account.next(account);
  }

  /*getAccountData(): Account {
    return this.account;
  }*/

  getBrands(accountUuid:string, pageSize: number=10, pageNumber: number=0, name=null) {
    let apiPath = `accounts/brands/${accountUuid}`;
    let finalUrl = `${environment.baseUrl}/${apiPath}`;

    let paramsRequest = new HttpParams();
    paramsRequest = paramsRequest.append('page', `${pageNumber}`);
    paramsRequest = paramsRequest.append('size', `${pageSize}`);
    name ? paramsRequest = paramsRequest.append('name', `${name}`) : null;

    return this.http.get(finalUrl, { params: paramsRequest });  
  }

}