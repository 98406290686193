<angular-editor 
    id="description" 
    name="description"
    [class.required]="required"
    [config]="editorConfig" 
    [(ngModel)]="description" 
    (ngModelChange)="changeDescription()"
    vexCtrlKeys
    (ctrlV)="onCtrlV()"
>
</angular-editor>