export const environment = {
  production: false,
  APIGatewayKey: "cYQFxKBlf13AaiLw58EvERHuwSOUbcR5zXQL2Kq3",
  baseUrl: "https://eiwqh1wdhb.execute-api.us-east-1.amazonaws.com/dev/api/v1",
  cepUrl: "https://ruzz7rb9m9.execute-api.us-east-1.amazonaws.com/dev/api/v1",
  portalCandidateUrl: "https://d3l809vcltmeth.cloudfront.net",
  fileBucket: "connekt-file-dev/gestor",
  fileBucketUrl: "https://connekt-file-dev.s3.amazonaws.com/gestor",
  portalBaseUrl: "https://dizgbylatumik.cloudfront.net",
  candidateApiUrl: "https://d2vpowl8kk9rm7.cloudfront.net/",
  zipcode: {
    url: "https://geocode.search.hereapi.com/v1",
    key: "TnyN38M2WEXwEjuCTCBEJ1BQte7FnbRqb5pVd7YPTF0",
  },
  awsmobile: {
    aws_project_region: "us-east-1",
    aws_content_delivery_bucket: "ui-candidato-app-prod",
    aws_content_delivery_bucket_region: "us-east-1",
    aws_content_delivery_url: "https://d2u26au46it1x7.cloudfront.net",
    aws_cognito_identity_pool_id:
      "us-east-1:b03812da-301e-47a8-b3ec-4713f3f55ac0",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_xjd4OjmlB",
    aws_user_pools_web_client_id: "6aklia8carq643stfqtf1i3s26",
  },
  cognitoCandidate: {
    aws_project_region: "us-east-1",
    aws_content_delivery_bucket: "ui-candidato-app-prod",
    aws_content_delivery_bucket_region: "us-east-1",
    aws_content_delivery_url: "https://d2u26au46it1x7.cloudfront.net",
    aws_cognito_identity_pool_id:
      "us-east-1:284b12da-2d9f-4ce2-aa67-8c163be3f74a",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_vofQLvbLt",
    aws_user_pools_web_client_id: "552njnd5viu3inkdmafh2imkdt",
  },
};
