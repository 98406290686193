<form class="social-profile-form" [formGroup]="socialProfilesForm" (submit)="addSocialProfile()" #formAnswer="ngForm">
    <h4 class="mt-4">Redes Sociais</h4>

    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
        <mat-form-field fxFlex="auto" fxFlex.gt-sm="20%">
            <mat-label>Tipo de Rede Social</mat-label>
            <mat-select formControlName="name" required>
                <mat-option [value]="type" *ngFor="let type of socialProfilesType">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="auto" fxFlex.gt-sm="60%">
            <mat-label>URL</mat-label>
            <input matInput formControlName="url" required>
        </mat-form-field>

        <button mat-button style="height: 50px;" color="primary" fxFlex="auto" fxFlex.gt-sm="20%">
            Adicionar rede social
        </button>
    </div>
</form>

<div class="social-profile" *ngIf="socialProfiles.length">
    <mat-card>
        <mat-card-content>
            <div class="px-gutter py-4 pl-0 pr-0" gdColumns="1fr" gdGap="16px">
                <div class="flex flex-wrap">
                    <div class="w-2/12">
                        <h4 class="text-center font-bold">Tipo de Rede Social</h4>
                    </div>
                    <div class="w-8/12">
                        <h4 class="text-center font-bold">URL</h4>
                    </div>
                    <div class="w-2/12">
                        &nbsp;
                    </div>
                </div>

                <ng-container *ngFor="let socialProfile of socialProfiles;let index=index;">
                    <div class="flex flex-wrap" *ngIf="!socialProfile?.deleted">
                        <div class="w-2/12">
                            <h4>{{ socialProfile?.name }}</h4>
                        </div>
                        <div class="w-8/12">
                            <h4 class="text-center">
                                {{ socialProfile?.url }}
                            </h4>
                        </div>
                        <div class="w-2/12 text-center">
                            <a (click)="removeSocialProfile(index)" matTooltip="Remover essa rede social">
                                <mat-icon [icIcon]="icremove_circle" class="text-red-700"></mat-icon>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-card-content>
    </mat-card>
</div>