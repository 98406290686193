import { Pipe, PipeTransform } from '@angular/core';
import { differenceInCalendarYears } from 'date-fns';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(dataOfBirth: string): number {
    const age = differenceInCalendarYears(
      new Date(),
      new Date(dataOfBirth)
    )
    return age;
  }

}
