<label class="font-bold">{{title}}</label>
<div>
    <input type="file" #fileUploader (change)="changeImage($event)" />
    <br>
    <a [href]="fileUploadedUrl" 
        target="_blank"
        class="selected_file"
        *ngIf="fileUploadedUrl">
        {{ fileUploadedUrl }}
    </a>
</div>