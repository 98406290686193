<div class="bg-pattern w-full h-full" fxLayout="column" fxLayoutAlign="center center">
    <div [@fadeInUp] class="card overflow-hidden w-full max-w-xs">
      <div class="p-6 pb-0" fxLayout="column" fxLayoutAlign="center center">
        <div class="fill-current text-center">
          <a href="/">
            <img class="w-32" src="assets/img/demo/logo-connekt.svg">
          <h2 class="title m-0">Empresas</h2>
          </a>
        </div>
      </div>
  
      <div class="text-center mt-4">
        <h2 class="title m-0">Recuperar a senha</h2>
        <h4 class="body-2 text-secondary m-0">Digite seu e-mail para recuperar sua senha.</h4>
      </div>
  
      <form [formGroup]="forgotPasswordForm" class="p-6 flex flex-col" 
        (submit)="recovery()" #formForgotPassword="ngForm">
        <mat-form-field>
          <mat-label>Informe seu E-mail</mat-label>
  
          <input formControlName="email" matInput required [attr.disabled]="showPasswordVerificationField" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" oninput="this.value = this.value.toLowerCase()">
          <mat-error *ngIf="
            (forgotPasswordForm.controls['email'].hasError('required') && forgotPasswordForm.controls['email'].dirty) ||
            (forgotPasswordForm.controls['email'].hasError('required') && formForgotPassword.submitted)">
            Não conseguimos recuperar sua senha sem seu e-mail
          </mat-error>
          <mat-error *ngIf="
            (forgotPasswordForm.controls['email'].hasError('email') && forgotPasswordForm.controls['email'].dirty) ||
            (!forgotPasswordForm.controls['email'].valid && forgotPasswordForm.controls['email'].dirty) ||
            (forgotPasswordForm.controls['email'].hasError('email')  && formForgotPassword.submitted)">
            E-mail inválido
          </mat-error>
        </mat-form-field>
          
        <div [hidden]="!showPasswordVerificationField">
          <mat-form-field class="w-full">
            <mat-label>Código de verificação</mat-label>
  
            <input formControlName="verificationCode" matInput [attr.required]="showPasswordVerificationField">
            
            <mat-error *ngIf="
              (forgotPasswordForm.controls['verificationCode'].hasError('required') && forgotPasswordForm.controls['verificationCode'].dirty) ||
              (forgotPasswordForm.controls['verificationCode'].hasError('required') && formForgotPassword.submitted)">
              Código de verificação obrigatório
            </mat-error>
          </mat-form-field>
        </div>
        <div [hidden]="!showPasswordVerificationField">
          <mat-form-field class="w-full">
            <mat-label>Nova Senha (No mínimo 6 caracteres)</mat-label>
  
            <input formControlName="password" [type]="'password'" matInput [attr.required]="showPasswordVerificationField">
            
            <mat-error *ngIf="
              (forgotPasswordForm.controls['password'].hasError('required') && forgotPasswordForm.controls['password'].dirty) ||
              (forgotPasswordForm.controls['password'].hasError('required') && formForgotPassword.submitted)">
              Senha é obrigatório
            </mat-error>
          </mat-form-field>
        </div>
        <div [hidden]="!showPasswordVerificationField">
          <mat-form-field [hidden]="!showPasswordVerificationField" class="w-full">
            <mat-label>Confirme a nova Senha</mat-label>
  
            <input formControlName="confirmPassword" [type]="'password'" matInput [attr.required]="showPasswordVerificationField">
            
            <mat-error *ngIf="
              (forgotPasswordForm.controls['confirmPassword'].hasError('required') && forgotPasswordForm.controls['confirmPassword'].dirty) ||
              (forgotPasswordForm.controls['confirmPassword'].hasError('required') && formForgotPassword.submitted)">
              Confirmação de senha é obrigatório
            </mat-error>
            <mat-error *ngIf="
              (forgotPasswordForm.hasError('passwordsNotEqual') && forgotPasswordForm.controls['confirmPassword'].dirty) ||
              (forgotPasswordForm.hasError('passwordsNotEqual') && formForgotPassword.submitted)">
              As senhas não são iguais
            </mat-error>
          </mat-form-field>
        </div>
  
        <button class="mt-2" color="primary" mat-raised-button *ngIf="!showPasswordVerificationField">
          Enviar link de recuperação
        </button>
        <button class="mt-2" color="primary" mat-raised-button *ngIf="showPasswordVerificationField">
          Alterar Senha
        </button>
  
        <div class="text-center pb-0 pt-0 mb-0">ou</div>
        <p class="text-secondary text-center mb-0">
          <a href="/">Volte para a tela de login</a>
        </p>
        
      </form>
    </div>
  </div>
  