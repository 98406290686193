import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseResourceService } from './base-resource.service';
import { Brand } from "../models/brand.model";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BrandService  extends BaseResourceService<Brand>{
  
  logo = new BehaviorSubject<string>('');
  banner = new BehaviorSubject<string>('');
  name = new BehaviorSubject<string>('');
  edit = new BehaviorSubject<boolean>(false);

  constructor(protected injector: Injector, private httpCliente: HttpClient) {
    super("brands", injector);
  }

  setLogo(logo: string) {
    this.logo.next(logo);
  }

  setBanner(banner: string) {
    this.banner.next(banner);
  }

  setName(name: string) {
    this.name.next(name);
  }

  editData(edit: boolean) {
    this.edit.next(edit);
  }

}