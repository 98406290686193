import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CognitoService } from './../../services/cognito.service';

@Component({
  selector: 'vex-modal-terms',
  templateUrl: './modal-terms.component.html',
  styleUrls: ['./modal-terms.component.scss']
})
export class ModalTermsComponent implements OnInit {

  @ViewChild('scroll') public scroll: ElementRef<any>;
  title: string;
  message: string;
  pdfSrc = "https://connekt-file.s3.amazonaws.com/file/Connekt+-+Termos+de+Uso+-+Gestor+v1.pdf";

  disableBtn: boolean = true;
  top: number;
  offSetHeight: number;
  scrollHeight: number;

  @ViewChild('divTerm') divTerm: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ModalTermsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TermsModal,
    private eleRef: ElementRef,
    private cognitoService: CognitoService,
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  public onPreviousSearchPosition() {
    this.scroll.nativeElement.scrollTop -= 20;
  }

  public onNextSearchPosition() {
    this.scroll.nativeElement.scrollTop += 20;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  acceptTermUse() {
    this.cognitoService.acceptTerm();
    this.dialogRef.close(true);
  }
}

export class TermsModal {
  constructor(public title: string, public message: string) { }
}