import { Injectable } from '@angular/core';
import { Route, Router, CanLoad, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { CognitoService } from '../services/cognito.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivate {
    constructor(
        private router: Router,
        private cognitoService: CognitoService
    ) { }

    checkAuthentication(route) {
        const currentUser = this.cognitoService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }

    canLoad(route: Route) {
        return this.checkAuthentication(route);
    }

    canActivate(route: ActivatedRouteSnapshot) {
        return this.checkAuthentication(route);
    }

}