import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { AuthGuard } from "./_auth/auth.guard";
import { Role } from "./models/role";

const routes: Routes = [
  {
    path: "login", //journeys
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "vagas",
        pathMatch: "full",
      },
      {
        path: "configuracao",
        loadChildren: () =>
          import("./configuracao/configuracao.module").then(
            (m) => m.ConfiguracaoModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "vagas",
        loadChildren: () =>
          import("./vagas/vagas.module").then((m) => m.VagasModule),
        canActivate: [AuthGuard],
      },
      {
        path: "talentos",
        loadChildren: () =>
          import("./modules/talentos/talentos.module").then(
            (m) => m.TalentosModule
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [
            Role.ROLE_CONNEKT,
            Role.ROLE_ADMIN,
            Role.ROLE_MANAGER,
            Role.ROLE_RECRUITER,
            Role.ROLE_CONNEKT_LIGHT,
          ],
        },
      },
      {
        path: "material-candidato/:uuid",
        loadChildren: () =>
          import("./material-candidato/material-candidato.module").then(
            (m) => m.MaterialCandidatoModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "testes",
        loadChildren: () =>
          import("./modules/matchings/matchings.module").then(
            (m) => m.MatchingsModule
          ),
        canLoad: [AuthGuard],
        data: {
          roles: [
            Role.ROLE_CONNEKT,
            Role.ROLE_ADMIN,
            Role.ROLE_MANAGER,
            Role.ROLE_RECRUITER,
          ],
        },
      },
      {
        path: "contas",
        loadChildren: () =>
          import("./contas/contas.module").then((m) => m.ContasModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.ROLE_CONNEKT] },
      },
      {
        path: "portal",
        loadChildren: () =>
          import("./portal/portal.module").then((m) => m.PortalModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.ROLE_CONNEKT] },
      },
      {
        path: "marcas",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./marcas/marcas.module").then((m) => m.MarcasModule),
        data: { roles: [Role.ROLE_CONNEKT, Role.ROLE_ADMIN] },
      },
      {
        path: "criar-vaga",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/criar-vaga/criar-vaga.module").then(
            (m) => m.CriarVagaModule
          ),
        data: {
          roles: [
            Role.ROLE_CONNEKT,
            Role.ROLE_ADMIN,
            Role.ROLE_MANAGER,
            Role.ROLE_RECRUITER,
            Role.ROLE_CONNEKT_LIGHT,
          ],
        },
      },
      {
        path: "suporte",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./suporte/suporte.module").then((m) => m.SuporteModule),
      },
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "usuarios",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "base-conhecimento",
        loadChildren: () =>
          import("./modules/knowledge-base/knowledge-base.module").then(
            (kb) => kb.KnowledgeBaseModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
