import { Component, Inject, LOCALE_ID, Renderer2 } from "@angular/core";
import { ConfigService } from "../@vex/services/config.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { NavigationService } from "../@vex/services/navigation.service";
import { MatDialog } from "@angular/material/dialog";

import icSettings from "@iconify/icons-ic/twotone-settings";
import icbranding_watermark from "@iconify/icons-ic/twotone-branding-watermark";
import icgroup from "@iconify/icons-ic/twotone-group";
import icTests from "@iconify/icons-ic/twotone-note-add";
import icLogout from "@iconify/icons-ic/twotone-exit-to-app";
import icwork from "@iconify/icons-ic/twotone-work";
import icSupport from "@iconify/icons-ic/twotone-headset-mic";
import icDashboard from "@iconify/icons-ic/twotone-dashboard";
import icCX from "@iconify/icons-ic/twotone-recent-actors";
import library from "@iconify/icons-ic/library-books";

import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { Style, StyleService } from "../@vex/services/style.service";
import { ConfigName } from "../@vex/interfaces/config-name.model";
import { Role } from "./models/role";
import { DashboardService } from "./services/dashboard.service";
import { CognitoService } from "./services/cognito.service";

import {
  SingleDialogComponent,
  SingleDialogModel,
} from "./shared/single-dialog/single-dialog.component";
import {
  ModalTermsComponent,
  TermsModal,
} from "./shared/modal-terms/modal-terms.component";
import { CookieService } from "ngx-cookie-service";
import { AccountService } from "./services/account.service";
import { AccountsService } from "./services/accounts.service";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "vex";
  parsedLoggedUser;
  hasDashboard = false;

  maintenanceMode: boolean = false;

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private dashboardService: DashboardService,
    private splashScreenService: SplashScreenService,
    private cognitoService: CognitoService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private accountService: AccountService
  ) {
    if (this.maintenanceMode) {
      this.showMaintenanceMessage();
    }

    Settings.defaultLocale = this.localeId;

    this.cognitoService.logoutUserExpTime.subscribe((result) => {
      if (result) {
        this.showLogoutMessage();
      }
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.getAccountData();

    this.route.queryParamMap
      .pipe(
        filter(
          (queryParamMap) =>
            queryParamMap.has("rtl") &&
            coerceBooleanProperty(queryParamMap.get("rtl"))
        )
      )
      .subscribe((queryParamMap) => {
        this.document.body.dir = "rtl";
        this.configService.updateConfig({
          rtl: true,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("layout")))
      .subscribe((queryParamMap) =>
        this.configService.setConfig(queryParamMap.get("layout") as ConfigName)
      );

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.styleService.setStyle(queryParamMap.get("style") as Style)
      );

    let loggedUser = localStorage.getItem("currentUser");
    if (loggedUser) {
      this.parsedLoggedUser = JSON.parse(loggedUser);
      // cookieService.get("accepted-term") != "accepted" ? this.showModalTermUser() : null;
    }

    this.navigationService.items = [
      {
        type: "link",
        label: "Minhas Vagas",
        route: "/vagas",
        icon: icwork,
      },
    ];
    const userRole = this.parsedLoggedUser
      ? this.parsedLoggedUser["role"]
      : null;
    if (!userRole) {
      return;
    }

    if (userRole != Role.ROLE_CUSTOMER) {
      this.navigationService.items.push({
        type: "link",
        label: "Banco de Talentos",
        route: "/talentos",
        icon: icgroup,
      });
    }

    if (userRole !== Role.ROLE_CONNEKT_LIGHT) {
      this.navigationService.items.push({
        type: "link",
        label: "Meus Testes",
        route: "/testes",
        icon: icTests,
      });
    }

    if (userRole !== Role.ROLE_CONNEKT_LIGHT) {
      this.navigationService.items.push(
        {
          type: "dropdown",
          label: "Configurações",
          icon: icSettings,
          children: [
            {
              type: "link",
              label: "Meus dados",
              route: "/usuarios/meus-dados",
              icon: icbranding_watermark,
            },
            this.parsedLoggedUser &&
            this.parsedLoggedUser["role"] == Role.ROLE_CONNEKT
              ? {
                  type: "link",
                  label: "Contas",
                  route: "/contas",
                  icon: icbranding_watermark,
                }
              : undefined,
            this.parsedLoggedUser &&
            (this.parsedLoggedUser["role"] == Role.ROLE_CONNEKT ||
              this.parsedLoggedUser["role"] == Role.ROLE_ADMIN)
              ? {
                  type: "link",
                  label: "Marcas",
                  route: "/marcas",
                  icon: icbranding_watermark,
                }
              : undefined,
            this.parsedLoggedUser &&
            (this.parsedLoggedUser["role"] == Role.ROLE_CONNEKT ||
              this.parsedLoggedUser["role"] == Role.ROLE_ADMIN ||
              this.parsedLoggedUser["role"] == Role.ROLE_MANAGER)
              ? {
                  type: "link",
                  label: "Usuários",
                  route: "/usuarios",
                  icon: icbranding_watermark,
                }
              : undefined,
          ],
        },
        this.parsedLoggedUser &&
          this.parsedLoggedUser["role"] == Role.ROLE_CONNEKT
          ? {
              type: "dropdown",
              label: "CX",
              icon: icCX,
              children: [
                {
                  type: "link",
                  label: "Portal",
                  route: "/portal",
                  icon: icbranding_watermark,
                },
              ],
            }
          : undefined,
        {
          type: "link",
          label: "Base de conhecimento",
          route: "/base-conhecimento",
          icon: library,
        },
        {
          type: "link",
          label: "Suporte",
          route: "/suporte",
          icon: icSupport,
        }
      );
    }

    this.navigationService.items.push({
      type: "link",
      label: "Sair",
      route: "/login",
      icon: icLogout,
    });

    if (loggedUser) {
      this.getDashboardUuid();
    }

    this.navigationService.items = this.navigationService.items.filter((x) => {
      if (x) {
        if (x["children"]) {
          x["children"] = x["children"].filter((x) => x !== undefined);
        }

        return x;
      }
    });
  }

  getAccountData() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if (!user) return;

    if (user.accountUuid === "perfil administrador") {
      this.updateConfig("Connekt", "assets/img/demo/favicon-32x32.png");
      return;
    }

    this.accountService.getById(user.accountUuid).subscribe((account) => {
      this.updateConfig(account.name, account.logo);
    });
  }

  updateConfig(name, logo) {
    this.configService.updateConfig({
      sidenav: {
        title: name,
        imageUrl: logo,
        showCollapsePin: false,
        state: "expanded",
      },
      footer: {
        visible: false,
      },
    });
  }

  getDashboardUuid() {
    let service;

    if (this.parsedLoggedUser["role"] == Role.ROLE_CONNEKT) {
      this.navigationService.items.splice(0, 0, {
        type: "link",
        label: "Dashboard (PREMIUM)",
        route: "/dashboard",
        icon: icDashboard,
      });
    } else if (this.parsedLoggedUser["role"] == Role.ROLE_ADMIN) {
      service = this.dashboardService.getAccountsDashboard(
        this.parsedLoggedUser["accountUuid"]
      );
    } else if (
      this.parsedLoggedUser["role"] == Role.ROLE_MANAGER ||
      this.parsedLoggedUser["role"] == Role.ROLE_RECRUITER
    ) {
      service = this.dashboardService.getBrandsDashboard(
        this.parsedLoggedUser["currentBrandsUuid"]
      );
    }

    if (this.parsedLoggedUser["role"] != Role.ROLE_CONNEKT && service) {
      service.subscribe((res) => {
        if (res && res["content"] && res["content"].length) {
          this.navigationService.items.splice(0, 0, {
            type: "link",
            label: "Dashboard",
            route: "/dashboard",
            icon: icDashboard,
          });
        }
      });
    }
  }

  showLogoutMessage() {
    const message = `Por medidas de segurança, encerramos sua sessão atual. Por favor, clique no botão OK e insira seu login e senha para acessar o sistema novamente.`;

    const dialogData = new SingleDialogModel("Sessão Expirada!", message);

    const dialogRef = this.dialog.open(SingleDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.cognitoService.logoutUser();
      }
    });
  }

  showMaintenanceMessage() {
    const message = `
      Prezado Cliente, esperamos que estejam bem.
      <br>
      Estamos passando por um momento de instabilidade, e estamos trabalhando para normalizar o quanto antes.
      <br>
      Pedimos gentilmente que acesse a plataforma posteriormente.
      <br>
      Desde já agradecemos a compreensão.
      <br>
      <br>
      <b>
      att,
      <br>
      Equipe Connekt.
      </b>
    `;

    const dialogData = new SingleDialogModel("", message);

    const dialogRef = this.dialog.open(SingleDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.cognitoService.logoutUser();
      }
    });
  }

  showModalTermUser() {
    const message = `
      Pedimos desculpas pelo inconveniente, mas no momento estamos em manutenção.
      Isso acontece pois estamos constantemente trabalhando em melhorias para sua 
      melhor experiência. Não se preocupe, já já o sistema estará normalizado!
    `;

    const dialogData = new TermsModal("Estamos em manutenção!", message);

    const dialogRef = this.dialog.open(ModalTermsComponent, {
      width: "70vw",
      maxHeight: "90vw",
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
      }
    });
  }
}
