import { environment } from '../../environments/environment';
import { BaseResourceModel } from '../models/base-resource.model';

import { Injector } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError, retry, shareReplay } from 'rxjs/operators';

export class BaseResourceService<T extends BaseResourceModel> {

  protected http: HttpClient;
  private baseUrlApi = environment.baseUrl;
  protected finalUrl: string;

  constructor(
    protected apiPath: string,
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  getBaseUrl() {
    this.finalUrl = `${this.baseUrlApi}/${this.apiPath}`;
  }

  getAll(params: Object = null): Observable<T[]> {
    this.getBaseUrl();
    let paramsRequest = new HttpParams();
    if (params) {
      for (let k in params) {
        paramsRequest = paramsRequest.append(k, params[k])
      }
    }

    return this.http.get(this.finalUrl, { params: paramsRequest }).pipe(retry(3), catchError(this.handleError), map(this.jsonDatatoResources), shareReplay());
  }

  getById(id: string): Observable<T> {
    this.getBaseUrl();
    let url = `${this.finalUrl}/${id}`;

    return this.http.get(url).pipe(retry(3), map(this.jsonDatatoResource), catchError(this.handleError), shareReplay());
  }

  create(resource: T): Observable<T> {
    this.getBaseUrl();

    return this.http.post(this.finalUrl, resource).pipe(retry(3), map(this.jsonDatatoResource), catchError(this.handleError));
  }

  update(resource: T, uuid: string = null): Observable<T> {
    this.getBaseUrl();
    let url = uuid ? `${this.finalUrl}/${uuid}` : this.finalUrl;

    return this.http.put(url, resource).pipe(retry(3), map(this.jsonDatatoResource), catchError(this.handleError));
  }

  patch(resource, uuid: string = null): Observable<T> {
    this.getBaseUrl();
    let url = uuid ? `${this.finalUrl}/${uuid}` : this.finalUrl;

    return this.http.patch(url, resource).pipe(map(this.jsonDatatoResource), catchError(this.handleError));
  }

  delete(uuid, resource: T = null): Observable<T> {
    this.getBaseUrl();
    let url = `${this.finalUrl}/${uuid}`;

    return this.http.delete(url).pipe(map(this.jsonDatatoResource), catchError(this.handleError));
  }

  protected jsonDatatoResources(jsonData: any[]): T[] {
    return jsonData;
  }

  protected jsonDatatoResource(jsonData: any): T {
    return jsonData as T;
  }

  protected handleError(error: any): Observable<any> {
    throw new Error(error);
  }
}