import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { CtrlKeysDirective } from './directives/ctrl-keys.directive';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon'
import { MatCheckboxModule } from '@angular/material/checkbox';

import { IconModule } from '@visurel/iconify-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { BreadcrumbsModule } from '../../@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from '../../@vex/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from '../../@vex/components/secondary-toolbar/secondary-toolbar.module';

import { ConnektLoaderModule } from './connekt-loader/connekt-loader.module';

import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';
import { DisapproveDialogComponent } from './disapprove-dialog/disapprove-dialog.component';

import { AgePipe } from './pipes/age/age.pipe';

import { SingleDialogComponent } from './single-dialog/single-dialog.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { ToolbarBrandsComponent } from './components/toolbar-brands/toolbar-brands.component';
import { SocialProfilesComponent } from './social-profiles/social-profiles.component';

import { ModalTermsComponent } from './modal-terms/modal-terms.component';
import { CustomScrollDirective } from './modal-terms/custom-scroll.directive';

import { ResourcesComponent } from './resources/resources.component';
import { WysiwygEditorComponent } from './components/wysiwyg-editor/wysiwyg-editor.component';

import { ImageUploadComponent } from "./components/image-upload/image-upload.component";

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        DisapproveDialogComponent,
        SingleDialogComponent,
        EmailDialogComponent,
        ToolbarBrandsComponent,
        SocialProfilesComponent,
        ModalTermsComponent,
        CustomScrollDirective,
        ResourcesComponent,
        WysiwygEditorComponent,
        ImageUploadComponent,
        CtrlKeysDirective,
        AgePipe
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDividerModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        IconModule,
        PdfViewerModule,
        BreadcrumbsModule,
        MatSnackBarModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatStepperModule,
        SecondaryToolbarModule,
        MatButtonToggleModule,
        PageLayoutModule,
        MatProgressSpinnerModule,
        ConnektLoaderModule,
        AngularEditorModule,
        FlexLayoutModule
    ],
    exports: [
        ConfirmDialogComponent,
        SingleDialogComponent,
        EmailDialogComponent,
        ToolbarBrandsComponent,
        SocialProfilesComponent,
        WysiwygEditorComponent,
        ResourcesComponent,
        BreadcrumbsModule,
        SecondaryToolbarModule,
        IconModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        MatPaginatorModule,
        PageLayoutModule,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatCardModule,
        MatInputModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        ConnektLoaderModule,
        MatFormFieldModule,
        CtrlKeysDirective,
        ImageUploadComponent,
        AgePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
