<div class="w-full h-full bg-pattern" fxLayout="column" fxLayoutAlign="center center">
    <div @fadeInUp class="card overflow-hidden w-full max-w-xs">
      <div class="p-6 pb-0" fxLayout="column" fxLayoutAlign="center center">
        <div class="fill-current text-center">
          <img class="w-32" src="assets/img/demo/logo-connekt.svg">
          <h2 class="title m-0">Empresas</h2>
        </div>
      </div>
  
      <div class="text-center mt-4">
        <h2 class="title m-0">Seja bem vindo</h2>
        <h4 class="body-2 text-secondary m-0">Conecte-se com milhões de candidatos</h4>
      </div>
  
      <form 
        [formGroup]="form" 
        class="p-6" 
        fxLayout="column" 
        fxLayoutGap="16px" 
        (submit)="send()"
        #loginform="ngForm">
        <div fxFlex="auto" fxLayout="column">
          <mat-form-field fxFlex="grow">
            <mat-label>E-mail</mat-label>
            <input formControlName="email" matInput required>
            <mat-error *ngIf="form.get('email').hasError('required')">Por favor, digite seu e-mail</mat-error>
            <mat-error 
            *ngIf="
                !form.get('email').hasError('required') &&
                !form.get('email').valid &&
                (form.get('email').dirty || loginform.submitted)
            "
            >E-mail inválido</mat-error>
        </mat-form-field>
          <mat-form-field fxFlex="grow">
            <mat-label>Senha</mat-label>
            <input [type]="inputType" formControlName="password" matInput required>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
              <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
              <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
            </button>
            <mat-hint>No mínimo seis caracteres</mat-hint>
            <mat-error *ngIf="form.get('password').hasError('required')">Por favor, digite sua senha</mat-error>
          </mat-form-field>
        </div>
  
        <!--<div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox class="caption" color="primary">Lembrar minha senha</mat-checkbox>
        </div>-->
  
        <button color="primary" mat-raised-button>
          Entrar
        </button>
  
        <p class="text-secondary text-center">
          <a [routerLink]="['recovery']">Esqueci minha senha</a>
        </p>
      </form>
    </div>
  </div>  