import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[vexCtrlKeys]'
})
export class CtrlKeysDirective {
  @Output() ctrlV = new EventEmitter();
  
  constructor() { }

  @HostListener('keydown.control.v') onCtrlV() {
    this.ctrlV.emit();
  }

}
