<h2 mat-dialog-title>Termos de Uso</h2>
<p>Leia o termo até o final, e clique em aceitar.</p>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography" appCustomScroll #appScrollElement="appCustomScroll" #scroll>
    <pdf-viewer [src]="pdfSrc" [render-text]="false" style="display: block;"></pdf-viewer>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="appScrollElement.disableBtn" (click)="acceptTermUse()"
        cdkFocusInitial>
        Aceitar
    </button>
</mat-dialog-actions>