import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import icFileToUpload from '@iconify/icons-ic/twotone-insert-drive-file';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icremove_circle from '@iconify/icons-ic/twotone-remove-circle';

import { ResourcesService } from 'src/app/services/resources.service';
import { UploadFileService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'vex-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  resourcesForm: UntypedFormGroup;
  resources = [];
  disableCheckbox = false;
  linkUrl;
  resource;
  imgResource;

  icFileToUpload = icFileToUpload;
  icDelete = icDelete;
  icremove_circle = icremove_circle;

  @Input("resourcesData") resourcesData;
  @Output("resourcesArr") resourcesArr = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
    private resourcesService: ResourcesService,
    private uploadFileService: UploadFileService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log('resourcesData', this.resourcesData)

    if (this.resourcesData && this.resourcesData.length) {
      this.resources = this.resourcesData;
      this.checkMainImage();
    }
  }

  createForm() {
    this.resourcesForm = this.fb.group({
      linkUrl: [null],
      name: [null],
      type: ['gallery'],
      title: [null],
      uuid: [null]
    });
  }

  removeResource(index) {
    this.resourcesService.deleteResource(this.resources[index]['uuid']).subscribe(
      res => {
        this.snackbar.open('Imagem excluída com sucesso!', 'OK', { duration: 3000 });
      },
      error => {
        this.snackbar.open('Erro ao excluir imagem!', 'OK', { duration: 3000, panelClass: 'red-snackbar' });
      }
    )

    this.resources.splice(index, 1);
  }

  emmitAnswer() {
    this.resourcesArr.emit({ 'resources': this.resources })

    this.resetFormValues()
  }

  resetFormValues() {
    this.resourcesForm.get('linkUrl').setValue(null);
    this.resourcesForm.get('name').setValue(null);
    this.resourcesForm.get('type').setValue(null);
    this.resourcesForm.get('title').setValue(null);
    this.resourcesForm.get('uuid').setValue(null);
  }

  setMainImage(event) {
    if (event.checked) {
      this.resourcesForm.get('name').setValue('main_image')
      this.resourcesForm.get('title').setValue('Imagem Principal')
    } else {
      this.resourcesForm.get('name').setValue('other_image')
      this.resourcesForm.get('title').setValue('Imagem Principal')
    }
  }

  checkMainImage() {
    this.resources.map(resource => {
      if (resource['name'] == 'main_image') {
        this.disableCheckbox = true
      }
    })
  }

  selectImage(event) {
    this.imgResource = event;
  }

  addImage() {
    this.getAwsSign('Portal-Resource', this.imgResource)
  }

  addResource() {
    this.resources.push(this.resourcesForm.value);

    this.checkMainImage();

    this.emmitAnswer();
  }

  clearUploadFile(event) {
    if (event.target) {
      event.target.value = null
    }
  }

  getAwsSign(fileType, media) {
    let contentType = media.target.files[0].type;
    let extension = contentType.split('/')[1];

    this.uploadFileService.getAwsSign(extension, fileType, contentType, '1234').subscribe(
      awsSign => {
        this.sendMediaAws(awsSign, contentType, media.target.files[0], fileType);
      },
      error => {
      }
    );
  }

  sendMediaAws(awsSign, contentType, media, fileType) {
    this.uploadFileService.sendMediaAws(awsSign, contentType, media).subscribe(
      res => {
        console.log('file_url', awsSign.file_url)
      },
      error => {
        this.snackbar.open('error', 'Erro ao enviar o banner da conta!');
      }
    );
  }
}