import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../environments/environment';
import { CognitoService } from '../services/cognito.service';

import { map, catchError } from 'rxjs/operators';

import moment from 'moment';

const logoutAfterTimeSeconds = 3600 * 5;

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private cognitoService: CognitoService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const currentUser = this.cognitoService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.token;
        const isApiUrl = request.url.startsWith(environment.baseUrl);

        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    'x-api-key': `${environment.APIGatewayKey}`
                }
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if ((this.loggedTime() > logoutAfterTimeSeconds) || (event['body'] && event['body']['status'] && event['body']['status'] == 407)) {
                    this.cognitoService.logoutUserByExpirationTime(true);
                }

                return event;
            }),
            catchError((error: any) => {
                return throwError(error);
            })
        );
    }

    loggedTime() {
        let timeNow = moment();
        let loginDateTime = localStorage.getItem('loginDateTime');
        let diffTimeLogin = moment.duration(timeNow.diff(loginDateTime));
        let diffTimeLoginInSeconds = diffTimeLogin.asSeconds();

        return diffTimeLoginInSeconds;
    }
}