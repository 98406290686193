import { Injectable, Injector } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from "../models/user";
import moment from 'moment';

import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  public currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser: Observable<User>;
  public logoutUserExpTime = new BehaviorSubject<Boolean>(false);

  constructor(
    private cookieService: CookieService
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }
  }

  logIn(username: string, password: string) {
    return Auth.signIn({
      username: username,
      password: password
    });
  }

  getCurrentUser() {
    return Auth.currentAuthenticatedUser({
      bypassCache: false
    });
  }

  createUser(userdata) {
    return Auth.signUp({
      username: userdata['email'],
      password: userdata['password'],
      attributes: {
        name: userdata['email'],
        phone_number: `+55${userdata['phone_number']}`,
        email: userdata['email'],
        'custom:brands_uuid': userdata['brandUuid'],
        'custom:accounts_uuid': "a3b57e99-6c04-4a7c-a1d2-2d641cf1c0e4"
      },
      validationData: []
    })
  }

  async logoutUser() {
    Auth.signOut().then(res => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('loginDateTime');
      location.href = '/login';
    })
      .catch(err => {
        throw new Error(err);
      });
  }

  setCurrentUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    this.setLoginDateTime();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  recoveryPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  confirmRecoveryPassword(email, verificationCode, password) {
    return Auth.forgotPasswordSubmit(email, verificationCode, password);
  }

  updateCurrentBrand(brandUuid: string) {
    let currentUser = this.currentUserValue;

    currentUser.currentBrandsUuid = brandUuid;
    this.setCurrentUser(currentUser);

  }

  setLoginDateTime() {
    localStorage.setItem('loginDateTime', moment().format());
  }

  logoutUserByExpirationTime(logout = false) {
    this.logoutUserExpTime.next(logout);
  }

  acceptTerm(){
    this.cookieService.set('accepted-term',"accepted");
  }

  getUserRole() {
    return JSON.parse(localStorage.getItem('currentUser')).role;
  }
}