import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { fadeInUp400ms } from '../../../@vex/animations/fade-in-up.animation';

import * as AWS from 'aws-sdk';
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import amplify from './../../../aws-exports';
import awsmobile from './../../../aws-exports';

import { User } from "../../models/user";

import { CognitoService } from '../../services/cognito.service';

@Component({
  selector: 'vex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ]
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;

  inputType = 'password';
  visible = false;

  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  user: User;
  cognitoIdentityServiceProvider: any;

  constructor(private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private cognitoService: CognitoService,
  ) {
    AWS.config.region = awsmobile.aws_cognito_region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
    });

    this.cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider(
      {
        credentials: AWS.config.credentials,
        region: awsmobile.aws_cognito_region
      },
    );
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  send() {
    if (this.form.valid) {
      this.logUserCognito();
    }
  }

  logUserCognito() {
    let email = this.form.value.email;
    let password = this.form.value.password;

    this.cognitoService.logIn(email, password)
      .then(user => {
        this.getUserData(user);
      })
      .catch(err => {
        if (err["code"]) {
          this.showMessageError(err);
        }
      });
  }

  getUserData(user) {
    var params = {
      Username: user.username, /* required */
      UserPoolId: amplify.aws_user_pools_id /* required */
    };
    this.cognitoIdentityServiceProvider.adminGetUser(params, (err, data) => {
      if (!err) {
        let uuid = user.username;
        let name = data.UserAttributes.filter(el => {
          return el.Name == "custom:user_name"
        });
        let marca = data.UserAttributes.filter(el => {
          return el.Name == "custom:brands_uuid"
        });

        let marca2 = data.UserAttributes.filter(el => {
          return el.Name == "custom:brands_uuid2"
        });

        let account = data.UserAttributes.filter(el => {
          return el.Name == "custom:accounts_uuid"
        });

        let fullOpportunities = data.UserAttributes.filter(el => {
          return el.Name == "custom:full_opportunities"
        });
        fullOpportunities = fullOpportunities && fullOpportunities[0] ? fullOpportunities[0]['Value'] : null;
        fullOpportunities = fullOpportunities == "1" ? true : false;


        let opportunitiesUuid = data.UserAttributes.filter(el => {
          return el.Name == "custom:opportunitiesUuid"
        });

        let token = user.signInUserSession.accessToken.jwtToken;
        let userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];

        marca2 = !marca2 || !marca2.length ? marca : marca2;

        this.user = {
          uuid: uuid,
          name: name[0].Value,
          role: userGroup[0],
          brandsUuid: marca2[0].Value,
          currentBrandsUuid: marca2[0].Value.split(";")[0],
          accountUuid: account[0].Value,
          token: token,
          acceptedTerm: false,
          fullOpportunities: fullOpportunities,
          opportunitiesUuid: opportunitiesUuid && opportunitiesUuid[0] ? opportunitiesUuid[0]['Value']  : null
        }

        this.cognitoService.setCurrentUser(this.user);
        this.snackbar.open('Bem vindo a plataforma Connekt', '', {
          duration: 3000,
          panelClass: 'green-snackbar'
        });

        location.href = "/";
      }
    })
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  showMessageError(err = null) {
    const errCode = err ? err['code'] : '';
    let errMessage;
    switch (errCode) {
      case 'UsernameExistsException':
        errMessage = 'Uma conta com este e-mail já está cadastrada.';
        break;
      case 'UserNotConfirmedException':
        errMessage = 'E-mail ainda não confirmado.';
        break;
      case 'UserNotFoundException':
        errMessage = 'Não encontramos uma conta com este e-mail.';
        break;
      case 'NotAuthorizedException':
        errMessage = 'E-mail ou senha inválidos';
        break;
      case 'CodeMismatchException':
        errMessage = 'Código Inválido';
        break;
      default:
        errMessage = 'Erro ao efetuar operação';
    }
    this.snackbar.open('red-snackbar', errMessage);
  }
}