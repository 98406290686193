import { UntypedFormGroup } from '@angular/forms';
//import * as moment from 'moment';

export class CustomFormValidator {
    // Validates URL
    static numeric(number): any {
        if (number.pristine) {
            return null;
        }
        const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
        number.markAsTouched();
        if (NUMBER_REGEXP.test(number.value)) {
            return null;
        }
        return {
            invalidNumber: true
        };
    }

    static validUrl(str) {
        var pattern = new RegExp(
            '^((ft|htt)ps?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?' + // port
            '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
            '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'
        ); // fragment locator
        return pattern.test(str);
    }

    static isValidCPF(strCPF) {
        if (strCPF && strCPF.value && strCPF.value.length == 11) {
            strCPF = strCPF.value;
            let sum;
            let mod;
            sum = 0;

            if (strCPF == "00000000000" ||
                strCPF == "11111111111" ||
                strCPF == "22222222222" ||
                strCPF == "33333333333" ||
                strCPF == "44444444444" ||
                strCPF == "55555555555" ||
                strCPF == "66666666666" ||
                strCPF == "77777777777" ||
                strCPF == "88888888888" ||
                strCPF == "99999999999"
            ) {
                return {
                    invalidCpf: true
                };
            }

            for (let i = 1; i <= 9; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            mod = (sum * 10) % 11;

            if ((mod == 10) || (mod == 11)) mod = 0;
            if (mod != parseInt(strCPF.substring(9, 10))) {
                return {
                    invalidCpf: true
                };
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            mod = (sum * 10) % 11;

            if ((mod == 10) || (mod == 11)) mod = 0;
            if (mod != parseInt(strCPF.substring(10, 11))) {
                return {
                    invalidCpf: true
                };
            }

            return null
        }
    }

    static passwordConfirming(group: UntypedFormGroup) {
        const password = group.controls.password.value;
        const confirmPassword = group.controls.confirmPassword.value;
        if (password !== confirmPassword) {
            group.controls.confirmPassword.setErrors({ NoPassswordMatch: true });
            return { passwordsNotEqual: true };
        }
        return null;
    }

    static validDate(dateString) {

        let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Parse the date parts to integers
        let date = dateString['value'],
            day,
            month,
            year;

        if (!date) {
            return null;
        }

        if (date.length != 6 && date.length != 8) {
            return {
                'invalidDate': true
            };
        }

        if (date.length == 8) {
            day = parseInt(date.substring(0, 2), 10);
            month = parseInt(date.substring(2, 4), 10);
            year = parseInt(date[2], 10);
        }

        if (date.length == 6) {
            day = parseInt("01", 10);
            month = parseInt(date.substring(0, 2), 10);
            year = parseInt(date.substring(2, 4), 10);
        }

        // Check the ranges of month and year
        if (month == 0 || month > 12)
            return {
                'invalidDate': true
            };

        if (date.length == 6)
            return null;

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        // Check the range of the day
        if (day > 0 && day <= monthLength[month - 1]) {
            /*return {
                'invalidDate': true
            };*/
        }
    }
}