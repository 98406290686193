import { Injectable, Injector } from "@angular/core";
import { Subject } from "rxjs/Subject";

import { UploadFile } from "../models/upload-file.model";

import { BaseResourceService } from "./base-resource.service";

import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import * as S3 from "aws-sdk/clients/s3";
import { environment } from "src/environments/environment";

import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: "root",
})
export class UploadFileService extends BaseResourceService<UploadFile> {
  uploadingFile$: Subject<boolean> = new Subject();

  constructor(protected injector: Injector, http: HttpClient) {
    super("file", injector);
  }

  sendMediaAws(mediaData, contentType, media) {
    const httpHeaders = new HttpHeaders({
      "Content-Type": contentType,
    });

    return this.http
      .put(mediaData["signed_url"], media, { headers: httpHeaders })
      .pipe(map((response) => response));
  }

  getAwsSign(extension = "jpeg", filetype, contentType, uuid = null) {
    let timeInMilliseconds = uuidv4();
    let fileName = uuid ? `${uuid}${timeInMilliseconds}` : timeInMilliseconds;
    let url = environment.candidateApiUrl;

    return this.http.get(`${url}/file`, {
      params: {
        fileName: `${fileName}.${extension}`,
        contentType: contentType,
        fileType: filetype,
      },
    });
  }

  uploadFile(upload) {
    this.uploadingFile$.next(upload);
  }
}
