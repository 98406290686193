<mat-card class="px-gutter shadow-none">
    <mat-card-header>
      <mat-icon mat-card-avatar>email</mat-icon>
      <mat-card-title>Envio de E-mail</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Assunto</mat-label>
                <input type="text" [(ngModel)]="data.subject" matInput>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <mat-label>Corpo do E-mail</mat-label>
                <textarea [(ngModel)]="data.message" matInput></textarea>
                <!-- <textarea [(ngModel)]="data.message" #emailMessage maxlength="500" matInput></textarea>
                <mat-hint align="end">{{ emailMessage.value?.length || 0 }}/500</mat-hint> -->
            </mat-form-field>
        </div>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-actions>
      <div fxLayout="row">
        <button color="warn" (click)="onDismiss()" mat-raised-button>
          Cancelar
        </button>
        <span fxFlex></span>
        <button color="primary" (click)="onConfirm()" mat-raised-button>
          Enviar
        </button>
      </div>
    </mat-card-actions>
</mat-card>