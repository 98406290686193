import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import icremove_circle from '@iconify/icons-ic/twotone-remove-circle';

@Component({
  selector: 'vex-social-profiles',
  templateUrl: './social-profiles.component.html',
  styleUrls: ['./social-profiles.component.scss']
})
export class SocialProfilesComponent implements OnInit {

  socialProfilesType = ["LINKEDIN", "FACEBOOK", "TWITTER", "GOOGLE", "INSTAGRAM"];
  socialProfilesForm: UntypedFormGroup;
  socialProfiles = [];

  icremove_circle = icremove_circle;

  @Input("socialProfilesData") socialProfilesData;
  @Output("socialProfilesArr") socialProfilesArr = new EventEmitter();

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.createForm();

    if (this.socialProfilesData && this.socialProfilesData.length) {
      this.socialProfiles = this.socialProfilesData;
    }
  }

  createForm() {
    this.socialProfilesForm = this.fb.group({
      name: [null, [Validators.required]],
      url: [null, [Validators.required]],
      uuid: [null]
    });
  }

  addSocialProfile() {
    if (this.socialProfilesForm.valid) {
      this.socialProfiles.push(this.socialProfilesForm.value);

      this.emmitAnswer();
    }
  }

  removeSocialProfile(index) {
    this.socialProfiles[index]['deleted'] = true;
    this.emmitAnswer();
  }

  emmitAnswer() {
    this.socialProfilesArr.emit({ 'socialProfiles': this.socialProfiles })

    this.resetFormValues()
  }

  resetFormValues() {
    this.socialProfilesForm.get('name').setValue(null);
    this.socialProfilesForm.get('url').setValue(null);
    this.socialProfilesForm.get('uuid').setValue(null);
  }
}
