import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Brand } from 'src/app/models/brand.model';
import { User } from 'src/app/models/user';
import { BrandService } from 'src/app/services/brand.service';
import { CognitoService } from 'src/app/services/cognito.service';

@Component({
  selector: 'vex-toolbar-brands',
  templateUrl: './toolbar-brands.component.html',
  styleUrls: ['./toolbar-brands.component.scss']
})
export class ToolbarBrandsComponent implements OnInit {

  brandsUser: Brand[] = [];
  loggedUser: User;
  brandsUuid: string[];
  form: UntypedFormGroup;

  constructor(
    private cognitoService: CognitoService,
    private brandService: BrandService,
    private fb: UntypedFormBuilder
  ) {
    this.cognitoService.currentUserSubject.subscribe(result => {
      this.setBrandsData();   
    });
  }

  ngOnInit() {
    if(!this.brandsUuid){
      this.setBrandsData();    
    }
    this.buildForm();
  }

  setBrandsData() {
    this.loggedUser = this.cognitoService.currentUserValue;      
    this.brandsUuid = this.loggedUser.brandsUuid.split(";");
    this.getBrands();
  }

  buildForm() {
    this.form = this.fb.group({
      brand: [null, [Validators.required]]
    });
  }

  getBrands() {
    let c = 0;
    for (let brandsUuid of this.brandsUuid) {
      this.brandService.getById(brandsUuid).subscribe(res => {
        res['uuid'] ? this.brandsUser.push(res) : null;
        c++;

        if (c == this.brandsUuid.length) {
          this.form.get('brand').setValue(this.loggedUser.currentBrandsUuid);
        }
      },
        error => { throw new Error(`Erro ao selecionar a brand:${error}`); });
    }

  }

  changeCurrentBrand(event) {
    let selectedBrand = event.value;
    this.cognitoService.updateCurrentBrand(selectedBrand);

    window.location.reload();

  }

}