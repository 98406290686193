import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';

import { UploadFileService } from "../../../services/upload-file.service";

@Component({
  selector: 'vex-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {
  
  currentFile: any;
  fileName: string;
  extension: string;
  contentType: string;
  
  @Input() title = 'Upload de arquivo'; 
  @Input() uploadOnChange = false;
  @Input() fileType: string;
  @Input() fileUploadedUrl: string;
  @Output() finishImageUploadEmitter = new EventEmitter();
  @Output() changeImageUploadEmitter = new EventEmitter();

  @ViewChild("uploadBannerFile") uploadBannerFile: ElementRef;
  
  constructor(
    private uploadFileService: UploadFileService
  ) { 
    this.uploadFileService.uploadingFile$.subscribe( res => {
      if(res) {
        this.uploadFile();
      }
    });
  }

  ngOnInit() {

    this.checkFileTypeData();

  }

  checkFileTypeData() {
    if(!this.fileType) {
      throw new Error('File URL not informed');
    }
  }

  setFileInformation() {
    if(this.currentFile) {
      this.setFileName();
      this.setExtension();
      this.setContentType();
    }  
  }

  setFileName() {
    this.fileName = this.currentFile.target.files[0].name;
  }

  setExtension() {
    if(this.fileName) {
      this.extension = this.fileName.substr(this.fileName.lastIndexOf(".") + 1);
    }
  }

  setContentType() {
    this.contentType = this.currentFile.target.files[0].type;
  }

  changeImage(event) {
    
    this.currentFile = event;

    this.setFileInformation();

    if(this.uploadOnChange) {
      this.uploadFile();
    }

    this.changeImageUploadEmitter.emit({uploadAction: true});
  }

  uploadFile() {
    this.uploadFileService.getAwsSign(this.extension, this.fileType, this.contentType)
      .pipe(
        take(1),
        switchMap(awsSign => {
          this.fileUploadedUrl = awsSign['file_url'];
          return this.uploadFileService.sendMediaAws(awsSign, this.contentType, this.currentFile.target.files[0]);
        })
      )
      .subscribe(
        res => {
          this.finishImageUploadEmitter.emit({success: true, fileUploadedUrl: this.fileUploadedUrl});
        },
        error => {
          this.finishImageUploadEmitter.emit({success: false, fileUploadedUrl: null});
        },
        () => {
          this.fileUploadedUrl = null
        }
      );      
  }

}
