<div fxLayout="column">
    <div fxLayout="row">
        <h1 class="title">Reprovar Candidato</h1>
    </div>
    
    <div class="mt-4" fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>Motivo</mat-label>
            <mat-select [(ngModel)]="motive">
                <mat-option *ngFor="let reason of disaproveReason | keyvalue: keepOrder" [value]="reason.key">
                    {{reason.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="mt-4" fxLayout="row" *ngIf="motive=='OTHERS'">
        <mat-form-field fxFlex>
            <mat-label>Observações</mat-label>
            <input matInput [(ngModel)]="observations">
        </mat-form-field>
    </div>
    
    <mat-divider></mat-divider>
    
    <div class="mt-4" fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="warn" (click)="onDismiss()">Cancelar</button>
        <button mat-raised-button class="ml-4" color="primary" (click)="onConfirm()">Reprovar</button>
    </div>
</div>