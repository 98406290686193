import { Component, 
  OnInit, 
  Input, 
  ViewChild, 
  Output, 
  EventEmitter,
  OnChanges, 
  SimpleChanges, 
  SimpleChange 
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

import * as AWS from 'aws-sdk';
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';
import amplify from './../../../aws-exports';
import awsmobile from './../../../aws-exports';

import { CustomFormValidator } from "../../shared/custom-form-validator";
import { CognitoService } from '../../services/cognito.service';

import { fadeInUp400ms } from '../../../@vex/animations/fade-in-up.animation';
import icMail from '@iconify/icons-ic/twotone-mail';


@Component({
  selector: 'vex-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
  animations: [fadeInUp400ms]
})
export class RecoveryPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup;
  @Input() showPasswordVerificationField:boolean = false;
  @Output() recoveryPasswordData = new EventEmitter();
  @Output() confirmRecoveryPasswordData = new EventEmitter();
  @ViewChild('formForgotPassword', {static: true}) formForgotPassword: NgForm; 
  icMail = icMail;
  cognitoIdentityServiceProvider;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private cognitoService: CognitoService,
    private snackbar: MatSnackBar,
    private _router: Router
  ) { 
    AWS.config.region = awsmobile.aws_cognito_region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
    });

    this.cognitoIdentityServiceProvider = 
      new CognitoIdentityServiceProvider(            
      {
        credentials:  AWS.config.credentials,             
        region: awsmobile.aws_cognito_region
      },
    );
  }

  ngOnInit() {     
    this.buildForgotPasswordForm();
  }

  protected buildForgotPasswordForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [null],
      verificationCode: [null],
      password: [null],
      confirmPassword: [null]
    });
  }

  setPasswordFormValidation(){
    if(this.showPasswordVerificationField){
      this.forgotPasswordForm.get('verificationCode').setValidators([Validators.required]);
      this.forgotPasswordForm.get('password').setValidators([Validators.required,,Validators.minLength(6)]);
      this.forgotPasswordForm.get('confirmPassword').setValidators([Validators.required]);
    }else{
      this.forgotPasswordForm.get('verificationCode').setValue(null);    
      this.forgotPasswordForm.get('password').setValue(null);    
      this.forgotPasswordForm.get('confirmPassword').setValue(null);    
      this.forgotPasswordForm.get('verificationCode').clearValidators();      
      this.forgotPasswordForm.get('password').clearValidators();      
      this.forgotPasswordForm.get('confirmPassword').clearValidators();      
    }

    this.forgotPasswordForm.setValidators(CustomFormValidator.passwordConfirming);

    this.forgotPasswordForm.get('verificationCode').updateValueAndValidity();
    this.forgotPasswordForm.get('password').updateValueAndValidity();
    this.forgotPasswordForm.get('confirmPassword').updateValueAndValidity();
  }

  recovery() {
    this.setPasswordFormValidation();
    
    if(this.forgotPasswordForm.invalid){
      return false;
    }

    let email = this.forgotPasswordForm.value.email;

    if(!this.showPasswordVerificationField){
      this.recoveryPassword(email);
    }else{
      this.confirmForgotPassword(email);
    }
  }

  recoveryPassword(email){
    this.cognitoService.recoveryPassword(email)
    .then(data => {
      //this._global.showSnackBar('green-snackbar', 'Código enviado com sucesso, por favor, verifique seu e-mail!');
      this.showPasswordVerificationField = true; 
      this.snackbar.open('Código enviado com sucesso, por favor, verifique seu e-mail!', '', {
        duration: 3000,
        panelClass:'green-snackbar'
      });     
      //this.showLoginErrorBlock = false;
    })
    .catch(err => {
      this.snackbar.open('Erro ao enviar código de verificação', '', {
        duration: 3000
      });
      //this.showMessageError(err);
    });
  }

  confirmForgotPassword(email) {
    
    let verificationCode = this.formForgotPassword.value.verificationCode;
    let password = this.formForgotPassword.value.password;
    
    this.cognitoService
      .confirmRecoveryPassword(email, verificationCode, password)
      .then(data=>{
        //this._global.showSnackBar('green-snackbar', 'Senha alterada com sucesso!');
        //this.showLoginErrorBlock=false;
        this.showPasswordVerificationField = false;
        this.snackbar.open('Senha alterada com sucesso!', '', {
          duration: 3000,
          panelClass:'green-snackbar'
        });
        this._router.navigate(['/login']);
      })
      .catch(err => {
        //this.showMessageError(err);
      });
  }

}