export enum ManualDisaproveReason {
  CANDIDATE_OUT_OF_PROFILE = 'Candidato fora do perfil',
  DECLINED_BY_THE_CANDIDATE = 'Declinado pelo candidato',
  BLOCKED_CANDIDATE = 'Candidato bloqueado',
  ABSENTE_CANDIDATE = 'Candidato ausente',
  LOCATION_CANDIDATE = 'Localização do candidato',
  CONTACT_WITHOUT_RETURN = 'Contato sem retorno',
  OTHERS = 'Outros'
}

export class AutomaticDisaprovedReason {    
  disaprovedReasons = {
    "Reproved by Note": "Nota",
    "Reproved by Age": "Idade",
    "Reproved by Geolocation": "Geolocalização"
  };
}

export class DisaprovedReason {

  disaproveReason = ManualDisaproveReason;
  automaticDisaprovedReason = new AutomaticDisaprovedReason();

  translateSubStageName(subStageName) {

    if(subStageName.includes("undefined")){
     subStageName = subStageName.split("-")[0].trim();
    }

    let automaticDisaprovedReason = this.automaticDisaprovedReason.disaprovedReasons[subStageName];
    let disapprovedReason;

    if (automaticDisaprovedReason) {
      disapprovedReason = automaticDisaprovedReason;
    } else if (this.disaproveReason[subStageName]) {
      disapprovedReason = this.disaproveReason[subStageName];
    } else {
      disapprovedReason = this.disaproveReason.OTHERS;
    }

    return disapprovedReason;
  }
}