import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManualDisaproveReason } from "../../models/enums/disapprove-reason";

@Component({
  selector: 'vex-disapprove-dialog',
  templateUrl: './disapprove-dialog.component.html',
  styleUrls: ['./disapprove-dialog.component.scss']
})
export class DisapproveDialogComponent implements OnInit {

  motive: string;
  observations: string;
  disaproveReason = ManualDisaproveReason;

  constructor(public dialogRef: MatDialogRef<DisapproveDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) { }

  ngOnInit() {}

  onConfirm(): void {
    this.dialogRef.close({motive: this.motive, observations: this.observations, disapproved: true});
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  keepOrder = (a, b) => {
    return a;
  }
}

export class ConfirmDialogModel {
  constructor(public title: string, public message: string) {
  }
}