<h1 mat-dialog-title>
    {{title}}
</h1>

<div mat-dialog-content>
    <p [innerHTML]="message"></p>
</div>

<div mat-dialog-actions class="justify-end">
    <button mat-raised-button color="primary" (click)="onConfirm()">OK</button>
</div>