import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnektLoaderComponent } from './connekt-loader/connekt-loader.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ConnektLoaderComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    ConnektLoaderComponent
  ]
})
export class ConnektLoaderModule { }
