import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { ErrorInterceptor } from './_auth/error.interceptor';
import { JwtInterceptor } from './_auth/jwt.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { AuthModule } from "./auth/auth.module";
import { ConnektLoaderModule } from './shared/connekt-loader/connekt-loader.module';

import { SharedModule } from "./shared/shared.module";

import { CookieService } from "ngx-cookie-service";
import { LoadingService } from './shared/components/loading/loading.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // Vex
    VexModule,
    CustomLayoutModule,
    AuthModule,
    ConnektLoaderModule,
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      CookieService,
      LoadingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
