<h4 class="mt-4">Imagens da Empresa</h4>

<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="8px" fxLayoutGap.gt-xs="24px">
    <div fxFlex="auto" fxLayout="row" fxLayoutGap="8px">
        <div fxFlex="auto" fxFlex.gt-sm="50%">
            <div style="display: flex;">
                <button color="primary" type="button" class="mb-4" mat-raised-button (click)="uploadLogoFile.click()">
                    Escolher imagem
                </button>
                <input hidden (click)="clearUploadFile($event)" (change)="selectImage($event)" #uploadLogoFile
                    type="file">
                <mat-icon class="ml-4 mt-1" *ngIf="imgResource" [icIcon]="icFileToUpload"
                    matTooltip="{{ imgResource.srcElement.files[0].name }}"></mat-icon>
                <a mat-button *ngIf="imgResource" matTooltip="Excluir imagem" color="warn" (click)="imgResource = ''">
                    <mat-icon [icIcon]="icDelete"></mat-icon>
                </a>

                <mat-checkbox class="ml-4" color="primary" [disabled]="disableCheckbox"
                    (change)="setMainImage($event)">
                    Imagem Principal?
                </mat-checkbox>
            </div>

            <button color="primary" type="button" mat-raised-button (click)="addImage()">
                Adicionar imagem
            </button>
        </div>
    </div>
</div>

<div class="social-profile" *ngIf="resources.length">
    <mat-card>
        <mat-card-content>
            <div class="px-gutter py-4 pl-0 pr-0" gdColumns="1fr" gdGap="16px">
                <div class="flex flex-wrap">
                    <div class="w-9/12">
                        <h4 class="text-center font-bold">URL da Imagem</h4>
                    </div>
                    <div class="w-2/12">
                        <h4 class="text-center font-bold">Imagem Principal</h4>
                    </div>
                    <div class="w-1/12">
                        &nbsp;
                    </div>
                </div>

                <ng-container *ngFor="let resource of resources;let index=index;">
                    <div class="flex flex-wrap" *ngIf="!resource?.deleted">
                        <div class="w-9/12">
                            <h4>{{ resource?.linkUrl }}</h4>
                        </div>
                        <div class="w-2/12">
                            <h4 class="text-center" *ngIf="resource?.name == 'main_image'">
                                Sim
                            </h4>
                            <h4 class="text-center" *ngIf="resource?.name != 'main_image'">
                                Não
                            </h4>
                        </div>
                        <div class="w-1/12 text-center">
                            <a (click)="removeResource(index)" matTooltip="Remover imagem">
                                <mat-icon [icIcon]="icremove_circle" class="text-red-700"></mat-icon>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-card-content>
    </mat-card>
</div>