<h1 mat-dialog-title>
    {{title}}
</h1>
  
<div mat-dialog-content>
    <p>{{message}}</p>
</div>

<div mat-dialog-actions class="justify-between">
    <button mat-button color="danger" (click)="onDismiss()">Não</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Sim</button>
</div>