import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private http: HttpClient) { }

  createResource(resource) {
    let finalUrl = `${environment.baseUrl}/resources`

    return this.http.post(finalUrl, resource);
  }

  deleteResource(uuid) {
    let finalUrl = `${environment.baseUrl}/resources/${uuid}`

    return this.http.delete(finalUrl);
  }
}
